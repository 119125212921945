/* src/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'global.css';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Jockey+One');
@layer base {

  /* html {
      font-family: "SegoeUI", system-ui, sans-serif;
    } */
  /* @screen sm {
    html {
      font-size: 12px;
    }
  }

  @screen md {
    html {
      font-size: 14px;
    }
  }

  @screen lg {
    html {
      font-size: 14px;
    }
  }

  @screen xl {
    html {
      font-size: 14px;
    }
  }

  @screen 2xl {
    html {
      font-size: 14px;
    }
  } */

  h1 {
    @apply text-3xl font-bold my-2;
  }

  h2 {
    @apply text-2xl font-semibold my-2;
  }

  h3 {
    @apply text-xl font-semibold my-2;
  }

  h4 {
    @apply text-lg font-semibold my-1;
  }

  h5 {
    @apply text-base font-semibold my-1;
  }

  button {
    @apply bg-secondary hover:bg-secondary_highlight text-white py-1 px-3 m-1 rounded;
  }

  button .selected {
    @apply bg-selected hover:bg-secondary_highlight text-white py-1 px-3 m-1 rounded;
  }

  input {
    @apply border border-gray-400 rounded p-1;
  }
}

@layer components {

  /* Modal related */
  .modal-overlay {
    @apply pointer-events-auto fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center;
  }

  .modal {
    @apply bg-white rounded-lg shadow-xl m-4 p-6 max-w-md w-full overflow-hidden;
  }

  .modal-header {
    @apply flex justify-between items-center pb-3 border-b border-gray-200 mb-4;
  }

  .modal-content {
    @apply overflow-auto;
  }

  .modal-close {
    @apply text-gray-700 hover:text-gray-600;
  }


  /* Catering Helper Component 
    Styles */
  .catering-helper {
    @apply bg-gray-100 rounded-lg shadow-xl m-4 p-6 max-w-md w-full overflow-hidden;
  }
}

@layer utilities {
  .w-160 {
    width: 40rem;
  }
}