/* global.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom nth-child styles */
/* Custom nth-child styles for details within .details-container */
.details-container > details:nth-child(odd) {
    @apply bg-[#FFDFAA];
    @apply p-2;
  }
  
  .details-container > details:nth-child(even) {
    @apply bg-[#FFC3AA];
    @apply p-2;
  }
  
  .details-container > details > summary {
    @apply p-2.5 cursor-pointer;
    @apply text-base;
  }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlayContent {
    position: relative;
    background-color: #fff;
    padding: 1rem;
    margin: 0;
    border-radius: 10px;
  }
  
  .overlayDescription {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.75rem;
    width:500px;
  }
  
  .overlayImage {
    width: 500px;
    height: auto;
    
    margin-bottom: 1px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;  /* Adjust the color as needed */
    padding: 0;
    outline: none;
  }